<template>
  <div class="sidebar">
    <div class="sidebar-top">
      <!-- <div class="user-icon">{{ userLabel }}</div>
      <div class="text-center">
        <h4>{{ userDoc.name }}</h4>
        <p>{{ userDoc.email }}</p>
      </div> -->
      <div class="user-icon"><i class="fal fa-typewriter fa-2x"></i></div>
      <h4>Blog</h4>
      <p>Admin</p>
    </div>
    <div class="sidebar-nav">
      <router-link :to="{ name: 'ArticlesList' }">
        <div
          @click="changeActive(0)"
          v-bind:class="{ active: menus[0] }"
          class="jbtn-nav"
        >
          <i class="fad fa-newspaper"></i
          ><span class="ml-3">All Articles</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'FeaturedArticlesList' }">
        <div
          @click="changeActive(1)"
          v-bind:class="{ active: menus[1] }"
          class="jbtn-nav"
        >
          <i class="fa fa-star"></i><span class="ml-3">Featured Articles</span>
        </div>
      </router-link>

            <router-link :to="{ name: 'DraftArticlesList' }">
        <div
          @click="changeActive(2)"
          v-bind:class="{ active: menus[2] }"
          class="jbtn-nav"
        >
          <i class="fad fa-pencil"></i><span class="ml-3">Drafts</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'CategoriesList' }">
        <div
          @click="changeActive(3)"
          v-bind:class="{ active: menus[3] }"
          class="jbtn-nav"
        >
          <i class="fad fa-th-list"></i><span class="ml-3">Categories</span>
        </div>
      </router-link>

            <router-link :to="{ name: 'AddEditArticle' }">
        <div
          @click="changeActive(4)"
          v-bind:class="{ active: menus[4] }"
          class="jbtn-nav"
        >
          <i class="fad fa-plus"></i><span class="ml-3">Add Article</span>
        </div>
      </router-link>

    </div>
  </div>
</template>

<script>
import C from "@/constants";
export default {
  data() {
    return {
      modeProspect: C.MODE.CONSULT_PROSPECTS,
      dash: 0,
      sub: 1,
      account: 2,
      password: 3,
      invoices: 4,
      download: 5,

      active: 0,
      menus: [true, false, false, false, false, false],
      type: this.$store.getters.getMode,
    };
  },
  methods: {
    changeActive: function(clicked) {
      // console.log(clicked);

      this.$set(this.menus, this.active, false);
      this.$set(this.menus, clicked, true);
      this.active = clicked;

      // console.log(this.active);
      // console.log(this.menus);
    },
    // display different options depending on user type, display mode
    isAdmin: function() {
      return this.$store.getters.getMode == C.MODE.CONSULT_PROSPECT;
    },
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
    userLabel: {
      get() {
        return this.$store.getters.getUserLabel;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/sidebar.scss";
</style>
